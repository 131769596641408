import {
  Box,
  HStack,
  Heading,
  Link,
  Spinner,
  Text,
  Textarea,
  VStack,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import Button from 'components/common/Button';
import React, { useEffect, useState } from 'react';
import { FaBackward, FaSave } from 'react-icons/fa';
import { RiImageAddLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useGetProjectContentQuery, useSaveProjectContentMutation } from 'services/api/projects';
import { BlogTopic, BlogType } from 'types';
interface ApprovalProps {
  topic: BlogTopic;
  handleGoBack: () => void;
  setSelectedBlog: React.Dispatch<React.SetStateAction<BlogType | undefined>>;
  handleGoForward: () => void;
  clientId: string;
}

const Approval = ({
  topic,
  handleGoBack,
  clientId,
  setSelectedBlog,
  handleGoForward,
}: ApprovalProps) => {
  const [blogContent, setBlogContent] = useState<string>('');

  const [googleDocLink, setGoogleDocLink] = useState<string>('');
  const { hasCopied, onCopy } = useClipboard(googleDocLink);

  const { data, error, isLoading } = useGetProjectContentQuery({ topic, clientId });

  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();

  const [saveProjectContent, { isLoading: isUpdating }] = useSaveProjectContentMutation();

  useEffect(() => {
    if (!isLoading && data) {
      setBlogContent(data.blog_content);
      setSelectedBlog(data);
    }
  }, [isLoading, data, error]);

  const toast = useToast();
  const navigate = useNavigate();

  const handleApprovalSave = async (afterSave: () => void) => {
    try {
      const response = await saveProjectContent({
        content: blogContent,
        clientId,
        topic_id: topic.id,
        topic,
      }).unwrap();

      if (response.url) {
        setGoogleDocLink(response.url); // Set the link in state immediately
        toast({
          title: 'Blog Saved',
          description: 'You can find the blog in your GDrive',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      afterSave();
    } catch (error) {
      console.error('Error saving project content:', error);
      toast({
        title: 'Blog Not Saved',
        description: 'Some errors occurred while saving! Check logs for more info.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const saveAndExit = async () =>
    await handleApprovalSave(() => {
      navigate('/client/hp/' + clientId);
    });

  const saveAndGenerataImages = async () =>
    await handleApprovalSave(() => {
      handleGoForward();
    });

  return (
    <Box>
      <VStack spacing={6} align="stretch">
        <Heading as="h2" size="md" mb={4}>
          Final Blog Copy Approval
        </Heading>
        <Text fontSize="md">Review blog content before saving the project.</Text>
        {isLoading && (
          <Box mb={4}>
            <Spinner /> Loading blog content
          </Box>
        )}
        {blogContent && (
          <Textarea
            backgroundColor="white"
            whiteSpace="pre-wrap"
            minHeight="300px"
            value={blogContent}
            onChange={(e) => setBlogContent(e.target.value)}
          />
        )}
        {googleDocLink && (
          <HStack>
            <Link color="blue.500" href={googleDocLink} isExternal>
              OPEN GOOGLE DOC
            </Link>
            <Button onClick={onCopy} ml={2}>
              {hasCopied ? 'Copied' : 'Copy'}
            </Button>
          </HStack>
        )}
        <HStack justifyContent="flex-start">
          <Button
            onClick={handleGoBack}
            variant="outline"
            colorScheme="purple"
            leftIcon={<FaBackward />}
          >
            Outline
          </Button>

          <Button
            colorScheme="pink"
            onClick={saveAndExit}
            isLoading={isUpdating}
            isDisabled={isLoading}
            loadingText="Saving..."
            leftIcon={<FaSave />}
          >
            Save Blog
          </Button>

          <Button
            colorScheme="purple"
            onClick={saveAndGenerataImages}
            isDisabled={isUpdating || isLoading}
            loadingText="Saving..."
            leftIcon={<RiImageAddLine />}
          >
            Add Images
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default Approval;
