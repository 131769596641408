import {
  Center,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { RiImageAddLine } from 'react-icons/ri';

import Button from 'components/common/Button';
import { FaRegImages } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useGetImageQuery, useSaveImageMutation } from 'services/api/projects';
import { ImageType } from 'types';

const Images = ({
  blogId,
  clientId,
  blogTitle,
}: {
  blogId: string;
  clientId?: string;
  blogTitle?: string;
}) => {
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [images, setImages] = useState<ImageType[]>([]);
  const [saveImages, { isLoading: isSaving }] = useSaveImageMutation();
  const [autoLoad, setAutoLoad] = useState(false);
  const toast = useToast();

  const {
    data: imageData,
    isLoading: initialLoading,
    isFetching: isImageFetching,
    refetch: handleGenerateImage,
    isError,
  } = useGetImageQuery(
    { blogId, clientId: clientId || '' },
    {
      skip: !blogId && !autoLoad,
    },
  );

  useEffect(() => setAutoLoad(true), []);

  useEffect(() => {
    if (Array.isArray(imageData)) {
      const newImages = imageData.filter(
        (newImage) => !images.find((existingImage) => existingImage.id === newImage.id),
      );
      setImages((prevImages) => [...newImages, ...prevImages]);
    }
  }, [imageData, images]);

  // Handle image selection
  const handleImageSelect = (imageId: string) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(imageId)
        ? prevSelected.filter((id) => id !== imageId)
        : [...prevSelected, imageId],
    );
  };

  const skipImagesStep = () => {
    navigate(clientId ? `/client/hp/${clientId}` : '/dashboard');
  };

  // Save selected images
  const handleSaveImages = async () => {
    try {
      const result = await saveImages({
        blogId,
        images: selectedImages,
        clientId: clientId || '',
      }).unwrap();

      toast({
        title: 'Images Saved',
        description: 'Selected images have been saved successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setSelectedImages([]);
      skipImagesStep();
    } catch (error) {
      console.error('Error saving images:', error);
      toast({
        title: 'Error Saving Images',
        description: 'An error occurred while saving the images. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={6} align="stretch" px={8} py={4}>
      <Heading size="md" color="purple.700">
        Generate Images for "{blogTitle || `Blog ${blogId}`}"
      </Heading>

      {isError && <Text color="red.500">Error loading images. Please try again.</Text>}

      {!initialLoading && images.length === 0 && (
        <Text>No images generated yet. Click "Generate New Image" to get started.</Text>
      )}

      <HStack justifyContent="flex-start">
        <Button
          colorScheme="teal"
          onClick={handleGenerateImage}
          isDisabled={images.length >= 5 || isSaving}
          leftIcon={<RiImageAddLine />}
          isLoading={isImageFetching}
          loadingText="Generating Image"
        >
          Generate Image
        </Button>
        <Button
          colorScheme="purple"
          onClick={handleSaveImages}
          isDisabled={selectedImages.length === 0 || initialLoading}
          isLoading={isSaving}
          loadingText="Saving Images"
          leftIcon={<Icon as={FaRegImages} />}
        >
          Save Images
        </Button>
        <Button onClick={skipImagesStep} leftIcon={<FaHome />} colorScheme="cyan">
          Skip Step
        </Button>
      </HStack>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {initialLoading ||
          (isImageFetching && (
            <Flex
              key={0}
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="sm"
              _hover={{ boxShadow: 'lg', transform: 'translateY(-4px)' }}
              position="relative"
            >
              <Center width="100%" height="100%">
                <Spinner size="lg" />
              </Center>
            </Flex>
          ))}

        {images.map((image, index) => (
          <Flex
            key={image.id}
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="sm"
            _hover={{ boxShadow: 'lg', transform: 'translateY(-4px)' }}
            position="relative"
            cursor="pointer"
          >
            <Checkbox
              position="absolute"
              top="4px"
              left="4px"
              size="lg"
              colorScheme="purple"
              isChecked={selectedImages.includes(image.id)}
              onChange={() => handleImageSelect(image.id)}
            />
            <Image
              src={`data:${image.mime_type || 'image/png'};base64,${image.image_base64}`}
              alt={`Image ${image.id}`}
              borderRadius="md"
              boxShadow="md"
              objectFit="cover"
              onClick={() => handleImageSelect(image.id)}
            />
          </Flex>
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default Images;
