import { BlogTopic, BlogType, GDocSummary, ProjectType } from 'types';
import { ImageType } from 'types';
import { RtkTagTypes, baseApi } from './baseApi';

export const ProjectsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({

    getImage: builder.query<ImageType[], { blogId: string; clientId: string }>({
      query: ({ blogId, clientId }) => ({
        url: `/blogs/${blogId}/image`,
        method: 'GET',
        headers: { 'client-id': clientId },
      }),
      transformResponse: (response: ImageType[]) => {
        // Ensure MIME type defaults to 'image/png' if none provided
        if (response.length > 0) {
          return response.map((image) => ({
            ...image,
            mime_type: image.mime_type || 'image/png',
          }));
        }
        return response;
      },
      providesTags: [RtkTagTypes.ProjectImage],
    }),

    saveImage: builder.mutation<boolean, { blogId: string; images: string[]; clientId: string }>({
      query: ({ blogId, images, clientId }) => ({
        url: `/blogs/${blogId}/images`,
        method: 'PUT',
        body: images,
        headers: { 'client-id': clientId }, 
      }),
    }),

    getProjects: builder.query<ProjectType[], void>({
      query: () => ({
        url: '/projects',
        method: 'GET',
      }),
      transformResponse: (response: ProjectType[]) => {
        // projects default sort by date descending
        response.sort((a, b) => Number(b.created) - Number(a.created));
        return response;
      },
      providesTags: [RtkTagTypes.Projects],
    }),

    getProjectSummary: builder.query<GDocSummary, string>({
      query: (clientId) => ({
        url: `/summary/${clientId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 600, // 10 minutes
      providesTags: [RtkTagTypes.ProjectsSummary],
    }),

    getProjectTopics: builder.query<BlogTopic[], GDocSummary | undefined>({
      query: (summary) => ({
        url: `/blog_topics`,
        method: 'POST',
        body: summary,
      }),
      keepUnusedDataFor: 600, // 10 minutes
      providesTags: [RtkTagTypes.ProjectsTopics],
    }),

    getProjectOutline: builder.query<
      string,
      { topic: BlogTopic; keywords: string[]; language: string; clientId: string }
    >({
      query: ({ topic, keywords, language, clientId }) => ({
        url: `/generate_outline`,
        method: 'POST',
        body: { topic, keywords, language },
        headers: { 'client-id': clientId },
      }),
      transformResponse: (response: { content: string }) => {
        return response.content;
      },
      keepUnusedDataFor: 600, // 10 minutes
      providesTags: [RtkTagTypes.ProjectsOutline],
    }),

    getProjectContent: builder.query<BlogType, { topic: BlogTopic; clientId: string }>({
      query: ({ topic, clientId }) => ({
        url: `/blogs`,
        method: 'POST',
        body: topic,
        headers: { 'client-id': clientId },
      }),
      keepUnusedDataFor: 1, // cache short-lived
    }),

    saveProjectOutline: builder.mutation<
      BlogTopic,
      { topic: BlogTopic; outline: string; keywords: string[]; clientId: string; language: string }
    >({
      query: ({ topic, outline, keywords, clientId, language }) => ({
        url: '/save_outline',
        method: 'POST',
        body: { topic, outline: { content: outline }, keywords, language },
        headers: { 'client-id': clientId },
      }),
    }),

    saveProjectContent: builder.mutation<
      { message: string; url: string },
      {
        content: string;
        clientId: string;
        topic_id: number;
        topic: BlogTopic;
      }
    >({
      query: ({ content, clientId, topic_id, topic }) => ({
        url: '/projects',
        method: 'POST',
        body: { content, topic },
        headers: { 'client-id': clientId, 'topic-id': topic_id.toString() },
      }),
      invalidatesTags: [RtkTagTypes.Projects],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectSummaryQuery,
  useGetProjectTopicsQuery,
  useGetProjectOutlineQuery,
  useGetProjectContentQuery,
  useSaveProjectOutlineMutation,
  useSaveProjectContentMutation,
  useGetImageQuery,
  useSaveImageMutation
} = ProjectsApi;
