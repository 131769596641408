import React from 'react';
import { ClientFormType, ClientFormValues } from 'types';
import { base64ToFile } from 'utils/files';
import { AddIcon, SmallCloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, FormLabel, HStack, Image, Input, InputGroup, Select, Text, Textarea, Tooltip, VStack, useToast } from '@chakra-ui/react';
import Button from 'components/common/Button';
import { clientIndustries } from 'utils/clients';

const parseCommaSeparatedString = (value: string) => (value ? value.split(',').map((item) => item.trim()) : []);

const GenerateTextAreaField = ({
  label,
  placeholder,
  isRequired,
  value,
  name = '',
  onChange,
}: {
  label: string;
  placeholder: string;
  isRequired: boolean;
  value: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
  return (
    <>
      <FormLabel m={0}>
        <HStack justifyContent="left">
          <Box>{label}</Box>
          <Tooltip label={`Eg: ${placeholder}`} aria-label={label} bg="gray.400">
            <InfoOutlineIcon />
          </Tooltip>
        </HStack>
      </FormLabel>
      <Textarea isRequired={isRequired} value={value} name={name} onChange={onChange} height="2em" />
    </>
  );
};

export const ClientForm = ({ onClose, client, saveClient, isSaving }: { client?: ClientFormValues; onClose: () => void; saveClient: (client: FormData) => any; isSaving: boolean }) => {
  const toast = useToast();
  const inputFileRef = React.useRef<null | HTMLInputElement>(null);

  const [formValues, setFormValues] = React.useState<ClientFormType>({
    www: client?.www ?? '',
    company: client?.company ?? '',
    email: client?.email ?? '',
    industry: {
      client_industry: client?.industry?.client_industry ?? '',
      industry_size: client?.industry?.industry_size ?? '',
    },
    location: client?.location ?? '',
    audience: client?.audience.join(',') ?? '',
    competitors: client?.competitors.join(',') ?? '',
    keywords: client?.keywords.join(',') ?? '',
    branding: {
      mission: client?.branding?.mission ?? '',
      vision: client?.branding?.vision ?? '',
      positioning: client?.branding?.positioning ?? '',
      perception: client?.branding?.perception ?? '',
      tone: client?.branding?.tone ?? '',
      marketing_goals: client?.branding?.marketing_goals.join(',') ?? '',
    },
    logo: client?.logo ? base64ToFile(client?.logo, 'logo.jpg') : null,
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFormValues({ ...formValues, logo: selectedFile });
    }
  };

  const handleFileClear = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.preventDefault();
    setFormValues({ ...formValues, logo: null });
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('www', formValues.www as string);
    formData.append('company', formValues.company);
    formData.append('email', formValues.email);
    formData.append('industry', JSON.stringify(formValues.industry));
    formData.append('location', formValues.location);
    formData.append('audience', JSON.stringify(parseCommaSeparatedString(formValues.audience)));
    formData.append('competitors', JSON.stringify(parseCommaSeparatedString(formValues.competitors)));
    formData.append('keywords', JSON.stringify(parseCommaSeparatedString(formValues.keywords)));
    formData.append('branding', JSON.stringify({ ...formValues.branding, marketing_goals: parseCommaSeparatedString(formValues.branding.marketing_goals) }));

    if (formValues.logo) {
      formData.append('logo', formValues.logo, formValues.logo.name);
    }

    try {
      const result = await saveClient(formData).unwrap();
      if (result) {
        toast({
          title: 'Client saved successfully',
          duration: 1000,
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      toast({
        title: 'Client not saved',
        description: 'An error occurred while saving. Please check the logs.',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex direction="column" gap={4}>
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Box width="70%">
            <InputGroup>
              <Input placeholder="Client website" value={formValues.www} onChange={(e) => setFormValues({ ...formValues, www: e.target.value })} />
            </InputGroup>
            <InputGroup>
              <Input placeholder="Company Name" value={formValues.company} onChange={(e) => setFormValues({ ...formValues, company: e.target.value })} />
            </InputGroup>
            <InputGroup>
              <Input placeholder="Email Address" value={formValues.email} onChange={(e) => setFormValues({ ...formValues, email: e.target.value })} />
            </InputGroup>
          </Box>
          <Box>
            <Input type="file" onChange={handleFileChange} ref={inputFileRef} style={{ display: 'none' }} />
            {!formValues.logo ? (
              <VStack onClick={() => inputFileRef.current?.click()} bg="purple.200" cursor="pointer" borderRadius="10" p={2} width="36">
                <AddIcon boxSize={16} />
                <Text align="center" fontSize="xs">
                  Add Client Logo
                </Text>
              </VStack>
            ) : (
              <VStack position="relative" p={2} width="36" borderWidth="1px" borderRadius="10">
                <SmallCloseIcon onClick={handleFileClear} position="absolute" boxSize={4} right={0} top={0} />
                <Image src={URL.createObjectURL(formValues.logo)} alt="client logo" boxSize={20} />
              </VStack>
            )}
          </Box>
        </HStack>

        {/* Industry Section */}

        <Box width="100%">
          <FormLabel>Client's Industry</FormLabel>
          <Select
            placeholder="Select Industry"
            value={formValues.industry.client_industry}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                industry: { ...formValues.industry, client_industry: e.target.value },
              })
            }
          >
            {clientIndustries.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </Box>

        {/* Industry Size */}
        <GenerateTextAreaField
          label="Industry Size"
          placeholder="5,000 companies in the UK"
          isRequired={true}
          value={formValues.industry.industry_size}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              industry: { ...formValues.industry, industry_size: e.target.value },
            })
          }
        />

        {/* Location and Target Audience */}
        <GenerateTextAreaField
          label="Location"
          placeholder="e.g. London, UK"
          isRequired={true}
          value={formValues.location}
          onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
        />
        <GenerateTextAreaField
          label="Target Audience"
          placeholder="Audience separated by commas"
          isRequired={true}
          value={formValues.audience}
          onChange={(e) => setFormValues({ ...formValues, audience: e.target.value })}
        />

        {/* Competitors and Keywords */}
        <GenerateTextAreaField
          label="Key Competitors"
          placeholder="Competitors separated by commas"
          isRequired={true}
          value={formValues.competitors}
          onChange={(e) => setFormValues({ ...formValues, competitors: e.target.value })}
        />
        <GenerateTextAreaField
          label="Keywords"
          placeholder="Keywords separated by commas"
          isRequired={true}
          value={formValues.keywords}
          onChange={(e) => setFormValues({ ...formValues, keywords: e.target.value })}
        />

        {/* Branding Fields */}
        <GenerateTextAreaField
          label="Mission"
          placeholder="Mission statement"
          isRequired={true}
          value={formValues.branding.mission}
          onChange={(e) => setFormValues({ ...formValues, branding: { ...formValues.branding, mission: e.target.value } })}
        />
        <GenerateTextAreaField
          label="Vision"
          placeholder="Vision statement"
          isRequired={true}
          value={formValues.branding.vision}
          onChange={(e) => setFormValues({ ...formValues, branding: { ...formValues.branding, vision: e.target.value } })}
        />
        <GenerateTextAreaField
          label="Positioning"
          placeholder="Brand positioning"
          isRequired={true}
          value={formValues.branding.positioning}
          onChange={(e) => setFormValues({ ...formValues, branding: { ...formValues.branding, positioning: e.target.value } })}
        />
        <GenerateTextAreaField
          label="Perception"
          placeholder="Brand perception"
          isRequired={true}
          value={formValues.branding.perception}
          onChange={(e) => setFormValues({ ...formValues, branding: { ...formValues.branding, perception: e.target.value } })}
        />
        <GenerateTextAreaField
          label="Tone"
          placeholder="Brand tone"
          isRequired={true}
          value={formValues.branding.tone}
          onChange={(e) => setFormValues({ ...formValues, branding: { ...formValues.branding, tone: e.target.value } })}
        />
        <GenerateTextAreaField
          label="Marketing Goals"
          placeholder="Marketing goals separated by commas"
          isRequired={true}
          value={formValues.branding.marketing_goals}
          onChange={(e) => setFormValues({ ...formValues, branding: { ...formValues.branding, marketing_goals: e.target.value } })}
        />

        <Button mt={4} type="submit" isLoading={isSaving}>
          Save Client
        </Button>
      </Flex>
    </form>
  );
};
