import { UserType } from 'types';
import { RtkTagTypes, baseApi } from './baseApi';

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserSelf: builder.query<UserType, void>({
      query: () => ({
        url: '/users/self',
        method: 'GET',
      }),
      providesTags: [RtkTagTypes.UsersSelf],
    }),
  }),
});

export const { useGetUserSelfQuery } = userApi;
