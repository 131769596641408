export const clientIndustries = [
  '3D printing',
  'Adtech',
  'Agtech',
  'Artificial intelligence and machine learning (Al/ML)',
  'Audiotech',
  'Augmented reaity (AR)',
  'Autonomous cars',
  'B2B payments',
  'Beauty / Fashion',
  'Big Data',
  'Cannabis',
  'Carsharing',
  'Cleantech and Cimate tech',
  'Cloudtech and DevOps',
  'Construction technology',
  'Cryptocurrency and blockchain',
  'Cybersecunty',
  'Digital health',
  'Ecommerce',
  'Edtech',
  'Femtech',
  'Fintech',
  'Foodtech',
  'Future of Work',
  'Gaming and eSports',
  'Hadware',
  'Healthtech',
  'HR tech',
  'Impact investing',
  'Industrials',
  'Infrastructure',
  'Insurtech',
  'Internet of Things (loT)',
  'Legal tech',
  'Life sciences',
  'Lifestyles of Health and Sustainability (LOHAS) and wellness',
  'Manufacturing',
  'Marketing tech',
  'Micro-mobilty',
  'Mobile',
  'Mobility tech',
  'Mortgage toch',
  'Nanotechnology',
  'Oil and gas',
  'Pet tech',
  'Real estate tech',
  'Restaurant tech',
  'Robotics and drones',
  'Sales / Operations / Customer Service',
  'Social Media / Community / Networking',
  'Software as a service (SaaS)',
  'Space tech',
  'Supply chain and logistics',
  'Travel and hospitalty',
  'Virtual reality (VRO)',
  'Wearables and quantified self',
  'Other',
];
