export const getDate = (date: string) => {
  const readableDate = new Date(Number(date) * 1000);
  return readableDate.toLocaleDateString();
};

export const getTime = (date: string) => {
  const readableDate = new Date(Number(date) * 1000);
  return readableDate.toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const getDateTime = (date: string, separator = ' at ') => {
  const readableDate = new Date(Number(date) * 1000);
  return `${readableDate.toLocaleDateString()}${separator}${readableDate.toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;
};
