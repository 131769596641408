import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider, theme } from '@chakra-ui/react';

import { REACT_APP_SAFE_MODE } from 'utils/consts';
import { store } from './app/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const AppWrapper = REACT_APP_SAFE_MODE ? React.StrictMode : 'div';

root.render(
  <AppWrapper>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  </AppWrapper>,
);
