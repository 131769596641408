import { CredentialResponse, TokenResponse } from '@react-oauth/google';
import { AuthViaGoogleReturn } from 'types';
import { baseApi } from './baseApi';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    authViaGoogle: builder.mutation<AuthViaGoogleReturn, TokenResponse | CredentialResponse>({
      query: (tokens) => ({
        url: '/auth',
        method: 'POST',
        body: {
          type: 'google',
          tokens,
        },
      }),
    }),
  }),
});

export const { useAuthViaGoogleMutation } = authApi;
