import { CodeResponse } from '@react-oauth/google';
import { UserSessionType } from 'types';
import Cookies from 'universal-cookie';

const SESSION_TYPE = 'session-type';
const SESSION_AUTH_KEY = 'session-id';
const SESSION_USER_INFO = 'user-info';
const SESSION_USER_ID = 'user-id';
const SESSION_TOKENS = 'tokens';

const cookies = new Cookies(null, { path: '/' });

export const setSessionType = (type: 'google') => cookies.set(SESSION_TYPE, type);

export const getSessionType = (): string => cookies.get(SESSION_TYPE) ?? '';

export const setSessionid = (jwt: string) => cookies.set(SESSION_AUTH_KEY, jwt);

export const getSessionid = (): string => cookies.get(SESSION_AUTH_KEY) ?? '';

export const setUserSession = (userInfo: UserSessionType) => cookies.set(SESSION_USER_INFO, userInfo);

export const getUserSession = (): UserSessionType => cookies.get(SESSION_USER_INFO) ?? {};

export const setUserId = (userId: string) => cookies.set(SESSION_USER_ID, userId);
export const getUserId = (): string => cookies.get(SESSION_USER_ID) ?? '';

export const setTokensSession = (tokens?: string[] | Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
  cookies.set(SESSION_TOKENS, tokens);
};

export const getTokensSession = (): string => {
  const tokens = cookies.get(SESSION_TOKENS);
  return tokens ? JSON.stringify(tokens) : '';
};

export const setStripeCheckoutSession = (sessionId: string) => window.localStorage.setItem('stripe-checkout-session', sessionId);
export const getStripeCheckoutSession = (): string | null => window.localStorage.getItem('stripe-checkout-session');

export const clearSession = () => {
  cookies.remove(SESSION_TYPE);
  cookies.remove(SESSION_AUTH_KEY);
  cookies.remove(SESSION_USER_INFO);
  cookies.remove(SESSION_USER_ID);
};
