import { useGetUserSelfQuery } from 'services/api/user';
import { ApiErrorType } from 'types';

export const useUserinfoHook = () => {
  const userSelfQuery = useGetUserSelfQuery();

  const isActive = !(userSelfQuery?.isError && (userSelfQuery?.error as ApiErrorType).status === 402);
  return {
    isActive,
    userSelfQuery,
  };
};
