import React from 'react';
import { AvatarProps, Avatar as ChakraAvatar, Image } from '@chakra-ui/react';

const Avatar = ({ logo, name, boxSize, borderRadius = '5px', ...props }: { logo?: string; name?: string; boxSize: string } & AvatarProps) =>
  logo ? (
    <Image src={logo} boxSize={boxSize} objectFit="contain" marginEnd={2} loading="lazy" borderRadius={borderRadius} referrerPolicy="no-referrer" {...props} />
  ) : (
    <ChakraAvatar name={name} showBorder={true} marginEnd={2} borderRadius={borderRadius} {...props} />
  );

export default Avatar;
