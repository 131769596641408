import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { DNS_PREFIX } from 'utils/consts';
import { DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, DATADOG_SITE } from 'utils/consts';

const applicationId = DATADOG_APPLICATION_ID;
const clientToken = DATADOG_CLIENT_TOKEN;
const site = DATADOG_SITE;
const env = DNS_PREFIX;

datadogRum.init({
  applicationId,
  clientToken,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site,
  service: 'yai-digital',
  env,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogLogs.init({
  clientToken,
  site,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});
