import { Box, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Avatar from 'components/common/Avatar';
import Button from 'components/common/Button';

import { useGetClientsQuery } from 'services/api/client';
import { useGetProjectsQuery } from 'services/api/projects';
import { FaRegArrowAltCircleDown } from 'react-icons/fa';

export const ClientsList = () => {
  const { data: clients, isLoading, isFetching } = useGetClientsQuery();
  const {
    data: projects,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
  } = useGetProjectsQuery();

  const limitIncrement = 4;
  const [limit, setLimit] = useState(limitIncrement);
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();

  const getProjectsNumber = (clientId: string) =>
    projects?.filter((project) => project.client_id === clientId).length || 0;

  if (isLoading || isFetching || isProjectsLoading || isProjectsFetching) {
    return (
      <Box mb={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <VStack align="stretch" spacing={2} width="100%">
      <Box display="flex" justifyContent="space-between" width="100%" px={2} mb={2}>
        <Text fontWeight="bold" color="purple.700">
          My Clients
        </Text>
        <Text fontWeight="bold" color="purple.700">
          Projects
        </Text>
      </Box>

      {clients?.slice(0, limit).map(({ id, name, logo }) => (
        <Box
          key={id}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          p={2}
          borderRadius="md"
          cursor="pointer"
          bg={clientId === id ? 'purple.100' : 'transparent'}
          transition="all 0.2s ease"
          _hover={{
            bg: 'purple.100',
            transform: 'translateX(-2px)',
          }}
          onClick={() => navigate(`/client/hp/${id}`)}
        >
          <Box display="flex" alignItems="center">
            <Avatar logo={logo} name={name} boxSize="6" size="xs" mr={2} />
            <Text
              fontSize="0.875em"
              fontWeight="medium"
              color={clientId === id ? 'purple.800' : 'purple.600'}
            >
              {name}
            </Text>
          </Box>

          <Text color="purple.600" fontWeight="medium">
            ({getProjectsNumber(id)})
          </Text>
        </Box>
      ))}

      {(clients?.length ?? 0) > limit && (
        <Box textAlign="center" width="100%" mt={2}>
          <Button
            colorScheme="teal"
            size="sm"
            onClick={() => setLimit(limit + limitIncrement)}
            leftIcon={<FaRegArrowAltCircleDown />}
          >
            Show More
          </Button>
        </Box>
      )}
    </VStack>
  );
};
