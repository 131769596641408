import { IconButton, Tooltip } from '@chakra-ui/react';
import React from 'react';

const ActionIcon = ({
  label,
  colourScheme,
  icon,
  onClick,
  disabled,
  iconProps,
  size = 'xs',
  ...props
}: {
  label: string;
  colourScheme?: string;
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  onClick: () => void;
  iconProps?: Record<string, any>;
  disabled?: boolean;
  size?: string;
  [x: string]: any;
}) => (
  <Tooltip label={label} aria-label={label} size={size} placement="top" bg={colourScheme} borderRadius="lg" {...props}>
    <IconButton icon={icon} aria-label={label} size={size} variant="solid" colorScheme={colourScheme} borderRadius="lg" onClick={onClick} isDisabled={disabled} {...iconProps} />
  </Tooltip>
);

export default ActionIcon;
