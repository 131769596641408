import { Icon, ButtonProps, useDisclosure } from '@chakra-ui/react';
import Button from 'components/common/Button';
import React from 'react';
import { ClientForm } from 'components/common/ClientForm';
import { Modal } from 'components/common/Modal';
import { useAddClientMutation } from 'services/api/client';
import { FaUserPlus } from 'react-icons/fa';

export const AddNewClient = (props: ButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [saveClient, { isLoading: isSaving }] = useAddClientMutation();

  return (
    <>
      <Button
        colorScheme="purple"
        {...props}
        onClick={() => {
          onOpen();
        }}
        leftIcon={<Icon as={FaUserPlus} boxSize="1.2em" color="white" />}
      >
        Add Client
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} title="Add New Client">
        <ClientForm onClose={onClose} saveClient={(client) => saveClient({ client })} isSaving={isSaving} />
      </Modal>
    </>
  );
};
