import SubcribeAlert from 'components/dashboard/SubcribeAlert';
import MainLayout from 'components/layout/MainLayout';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSessionid } from 'utils/sessionStorage';
import { Projects } from 'components/dashboard/Projects';
import { useGetClientsQuery } from 'services/api/client';
import { Heading } from '@chakra-ui/react';

const HomePage: React.FC = () => {
  const session = getSessionid();
  const navigate = useNavigate();

  const { data: clients } = useGetClientsQuery();

  const { clientId } = useParams<{ clientId: string }>();

  const client = clients?.find((client) => client.id === clientId);

  useEffect(() => {
    if (!session) {
      navigate('/');
    }
    if (!clientId) {
      navigate('/dashboard');
    }
  }, [session, clientId, navigate]);
  return (
    <MainLayout overflowY="auto">
      <SubcribeAlert />
      {/* Recent Projects Section */}
      <Heading as="h1" color="primary" paddingX={8} textTransform="capitalize">
        {client?.name}
      </Heading>
      <Projects clientId={clientId} perPage={12} />
    </MainLayout>
  );
};

export default HomePage;
