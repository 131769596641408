import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Images from 'components/blog/Images';
import MainLayout from 'components/layout/MainLayout';
import { getSessionid } from 'utils/sessionStorage';

const ImageDashboard: React.FC = () => {
  const navigate = useNavigate();
  const session = getSessionid();
  const { blogId } = useParams<{ blogId: string }>();

  useEffect(() => {
    if (!session) {
      navigate('/');
    }
    if (!blogId) {
      navigate('/dashboard');
    }
  }, [session, blogId, navigate]);

  if (!blogId || !session) {
    return null;
  }

  return (
    <MainLayout>
      <Images blogId={blogId} />
    </MainLayout>
  );
};

export default ImageDashboard;
