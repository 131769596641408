import { Box, ChakraProps, Flex } from '@chakra-ui/react';
import Sidebar from 'components/dashboard/Sidebar';
import React from 'react';

const MainLayout = ({ children, ...props }: React.PropsWithChildren & ChakraProps) => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }} // Stack vertically on smaller screens
      minH="100vh" // Full viewport height
      overflow="hidden"
    >
      {/* Sidebar */}
      <Box w={{ base: '100%', md: '30%', xl: '20%' }} flexShrink={0} bg="white">
        <Sidebar />
      </Box>

      {/* Main Content */}
      <Box
        flex="1" // Allows the main content to expand
        p={5}
        overflow="auto"
        {...props}
      >
        {children}
      </Box>
    </Flex>
  );
};

export default MainLayout;
