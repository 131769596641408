import React from 'react';
import { Button as ButtonChakra, ButtonProps } from '@chakra-ui/react';

const Button = ({ children, ...props }: ButtonProps) => {
  return (
    <ButtonChakra borderRadius="full" {...props}>
      {children}
    </ButtonChakra>
  );
};

export default Button;
