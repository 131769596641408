import { useToast } from '@chakra-ui/react';
import { useGoogleLogin } from '@react-oauth/google';
import Button from 'components/common/Button';
import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { useAuthViaGoogleMutation } from 'services/api/auth';
import { clearSession, setSessionType, setSessionid, setUserId, setUserSession } from 'utils/sessionStorage';

export const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const toast = useToast();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        clearSession();
        const { data } = await authViaGoogle(tokenResponse as any);

        if (data) {
          setSessionType('google');
          setSessionid(data.session_id);
          setUserSession(data.user_info);
          setUserId(data.user_id);

          navigate('/dashboard');
        } else {
          throw new Error('Error on authentication. Try again!');
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Error on authentication. Try again!',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    },
    onError: (error) => {
      toast({
        title: 'Error',
        description: 'Error on authentication. Try again!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setIsLoading(false);
    },
    onNonOAuthError: (error) => {
      setIsLoading(false);
    },
    flow: 'auth-code',
    scope: 'openid profile email https://www.googleapis.com/auth/drive',
    redirect_uri: 'postmessage',
  });

  const [authViaGoogle, { isLoading: isGoogleAuthUpdating }] = useAuthViaGoogleMutation();

  return (
    <>
      <Button
        isLoading={isLoading || isGoogleAuthUpdating}
        loadingText="Submitting"
        leftIcon={<FcGoogle />}
        onClick={() => {
          setIsLoading(true);
          login();
        }}
        borderRadius="1.5em"
        border="1px solid black"
        _hover={{ bg: 'rgba(140, 30, 205, 0.2)' }}
        backgroundColor="white"
        mt="1em"
        p="1.5em 4em"
        w="20em"
        fontWeight="normal"
      >
        Sign In With Google
      </Button>
    </>
  );
};
