import React, { useEffect, useState } from 'react';
import { BlogTopic } from 'types';

import { HStack, Heading, Spinner, Text, Box, Textarea, useToast } from '@chakra-ui/react';
import Button from 'components/common/Button';
import { useGetProjectOutlineQuery, useSaveProjectOutlineMutation } from 'services/api/projects';
import { FaBackward, FaSave } from 'react-icons/fa';
import { IoIosRefresh } from 'react-icons/io';

interface OutlineProps {
  language: string;
  keywords: string[];
  selectedTopic: BlogTopic;
  setSelectedTopic: React.Dispatch<React.SetStateAction<BlogTopic | undefined>>;
  handleGoBack: () => void;
  handleGoForward: () => void;
  clientId: string;
}

const Outline = ({
  language,
  keywords,
  selectedTopic,
  setSelectedTopic,
  handleGoBack,
  handleGoForward,
  clientId,
}: OutlineProps) => {
  const [outline, setOutline] = useState<string>('');
  const toast = useToast();

  const {
    data: AiOutline,
    isLoading: isOutlineLoading,
    refetch,
    isFetching,
  } = useGetProjectOutlineQuery({ topic: selectedTopic, keywords, language, clientId });
  const [saveProjectOutline, { isLoading: isUpdating }] = useSaveProjectOutlineMutation();

  const handleConfirmClick = async () => {
    const result = await saveProjectOutline({
      topic: selectedTopic,
      outline,
      keywords,
      clientId,
      language,
    });
    if (result.data && !result.error) {
      // Set the new topic returned
      setSelectedTopic(result.data);
      handleGoForward();
    } else {
      toast({
        title: 'No outline saved',
        description: 'Error occured during saving. Check logs!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (AiOutline) {
      setOutline(AiOutline);
    }
  }, [AiOutline]);

  if (!selectedTopic) {
    // if by exception we have no selected topic go back to previous step
    handleGoBack();
    return null;
  }

  return (
    <Box>
      <Heading as="h2" size="md" mb={4}>
        Blog Outline
      </Heading>

      {isOutlineLoading || isFetching ? (
        <Box mb={4}>
          <Spinner mr="1em" />
          {isFetching && 'Re-'}Generating the outline
        </Box>
      ) : (
        <Box mb={8}>
          <Text fontSize="md" mb={8}>
            Please edit the outline suggested by YAi or create your own outline from scratch.
          </Text>
          <Textarea
            backgroundColor="white"
            value={outline}
            onChange={(e) => setOutline(e.target.value)}
            placeholder="Your outline goes here"
            size="sm"
            minHeight="300px"
          />
        </Box>
      )}

      <HStack justifyContent="flex-start">
        <Button
          onClick={handleGoBack}
          variant="outline"
          colorScheme="purple"
          leftIcon={<FaBackward />}
        >
          Keywords
        </Button>

        <Button
          colorScheme="teal"
          onClick={() => {
            setOutline('');
            refetch();
          }}
          isDisabled={isOutlineLoading || isFetching}
          isLoading={isFetching}
          loadingText="Regenerating Outline"
          leftIcon={<IoIosRefresh />}
        >
          Regenerate Outline
        </Button>
        <Button
          colorScheme="purple"
          onClick={handleConfirmClick}
          isLoading={isUpdating}
          isDisabled={isOutlineLoading || isFetching}
          loadingText="Saving Outline"
          leftIcon={<FaSave />}
        >
          Save
        </Button>
      </HStack>
    </Box>
  );
};

export default Outline;
